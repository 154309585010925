@import "_mixins.scss";
.core-wrapper {
    display: flex;
    flex-flow: row nowrap;
    height: 100vh;

    &.is-person {flex-flow: column nowrap}

    .core-content {
        overflow: auto;
        flex: 1 1 auto;
        background: var(--c-white);
        scroll-behavior: smooth;
    }

    @media screen and (max-width: 64rem) {flex-flow: column nowrap}
}
