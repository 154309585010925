@import "_mixins.scss";
@charset "utf-8";

@import '_variables.scss';
@import '_keyframes.scss';

::selection {
    /* Using CSS vars in pseudos can be problematic, so we fallback to harcoded values */
    background: #17E;
    background: var(--c-selection, #17E);
}
img::selection {
    background: #17E8;
    background: color-mix(in srgb, var(--c-selection, #17E), transparent);
}

::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
    background: #0001;
    background: var(--c-black-t1, #0001);
    border-radius: 1rem;

    &-thumb {
        background: var(--c-black-t3);
        border-radius: 1rem;
    }
}

.thick-scrollbar {
    &::-webkit-scrollbar {
        width: 1rem;
        height: 1rem;
    }

    &.left-not-rounded::-webkit-scrollbar {border-radius: 0 .5rem .5rem 0}
}

.narrow-scrollbar {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: .25rem;
        height: .25rem;
    }

    &.left-not-rounded::-webkit-scrollbar {border-radius: 0 .25rem .25rem 0}
}

*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    scrollbar-color: var(--c-black-t3) var(--c-black-t1);
}

:root {
    font-family: var(--ff-body);
    font-size: 100%;
    line-height: 1.47;
    tab-size: 4;
    width: 100%;
    height: 100%;
    background: var(--c-white);
    color: var(--c-black);
    scroll-behavior: smooth;
}

body {
    margin: 0;
    width: 100%;
    height: 100%;
    background: inherit;
}

h1,
body .h1 {
    @include h1();
    margin: 0;
}

h2,
body .h2 {
    @include h2();
    margin: 0;
}

h3,
body .h3 {
    @include h3();
    margin: 0;
}

h4,
body .h4 {
    @include h4();
    margin: 0;
}

h5,
body .h5 {
    @include h5();
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
    transition: color .15s,
                filter .15s,
                opacity .15s,
                background .15s,
                box-shadow .15s,
                outline .15s;

    &:hover {text-decoration: none}

    &:not(
        button,
        [type='button'],
        [type='submit'],
        [type='reset'],
        .button,
        .btn,
        [class*='button']
    ):hover
    {filter: brightness(1.1)}
}

hr {
    width: 100%;
    height: 1px;
    border: none;
    background: linear-gradient(90deg, #0000, var(--c-black-t3), #0000);
}

[type='radio'] {
    --size: 1.2rem;

    appearance: none;
    flex: 0 0 auto;
    font-family: inherit;
    font-size: 1rem;
    line-height: var(--size);
    vertical-align: middle;
    transform: scale(0.99);
    position: relative;
    width: var(--size);
    height: var(--size);
    padding: 0;
    border: 1px solid currentColor;
    border-radius: 50%;
    margin-inline: .5em;
    color: var(--c-black-t3);
    cursor: pointer;
    transition: color .15s;

    &::before {
        content: '';
        opacity: 0;
        transform: scale(.5);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        color: var(--c-action);
        background: currentColor;
        transition: opacity .15s;
    }

    &:not(:disabled):hover {
        &::before {opacity: .3}
    }

    &:not(:disabled):checked {
        color: var(--c-action);

        &::before {opacity: 1}
    }

    &:checked {cursor: default}

    ~ * {vertical-align: middle}
}
[type='checkbox'] {
    --size: 1.2rem;

    flex: 0 0 auto;
    font-family: inherit;
    font-size: 1rem;
    line-height: var(--size);
    vertical-align: middle;
    transform: scale(0.99);
    position: relative;
    width: var(--size);
    height: var(--size);
    padding: 0;
    border: 1px solid currentColor;
    margin-inline: .5em;
    color: var(--c-black-t3);
    accent-color: currentColor;
    cursor: pointer;
    transition: color .15s;

    &:not(:disabled, [disabled], [inert]):checked {
        color: var(--c-action);

        &::before {opacity: 1}
    }

    ~ * {vertical-align: middle}
}

label {
    display: inline;
    margin: 0;

    &:has(
        input:is(
            [type='checkbox'],
            [type='radio']
        )
    ) {cursor: pointer}
}

img {
    max-width: 100%;
    max-height: 100%;
}

svg {
    flex: 0 0 auto;
    max-width: 100%;
    max-height: 100%;
    opacity: 1;

    text {cursor: text}

    .invert-dark {fill: var(--c-white)}
    .invert-light {fill: var(--c-black)}
}

address {margin: 0}
table {border-spacing: 0}
dl {margin: 0}
dd {margin: 0}

fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

legend {
    font-weight: 700;
    padding: 0 .5em;
}

:is(
    button,
    [type='button'],
    [type='submit'],
    [type='reset'],
    .button,
    .btn
) {
    @include button-text();
    appearance: none;
    flex: 0 0 auto;
    align-items: center;
    font-size: 1em;
    font-family: inherit;
    word-break: break-word;
    padding: 0;
    border: 0;
    margin: 0 auto;
    border-radius: 1.5em;
    background: none;
    color: inherit;
    user-select: none;
    transition: color .15s,
                filter .15s,
                opacity .15s,
                background .15s,
                box-shadow .15s;

    &:not(
        &[disabled],
        &.disabled,
        &:disabled
    ) {
        cursor: pointer;

        &:hover {
            text-decoration: none;
            filter: brightness(1.1);
        }

        &:focus {
            outline: 1px dotted var(--c-white);
            outline-offset: -3px;
        }
        &:active {filter: brightness(.9)}
    }


    &[disabled],
    &.disabled,
    &:disabled {
        filter: brightness(.9) contrast(.8) saturate(0);
        box-shadow: 0 0 0 transparent;
        cursor: default;

        * {pointer-events: none !important} /* stylelint-disable-line */
    }
}

.button,
.btn {
    @include button-text();
    box-sizing: border-box;
    align-items: center;
    display: inline-block;
    background: none;
    max-width: 100%;
    border: 0;
    padding: 0;
    border-radius: 1.5em;
    text-align: center;
    cursor: pointer;

    &:not(
        &[disabled],
        &.disabled,
        &:disabled
    ) {
        cursor: pointer;

        &:hover {
            text-decoration: none;
            filter: brightness(1.1);
        }

        &:focus {
            outline: 1px dotted var(--c-white);
            outline-offset: -3px;
        }

        &:active {filter: brightness(.9)}
    }

    &--primary {@include button-primary()}
    &--secondary {@include button-secondary()}
    &--green {@include button-green()}
    &--green-s {@include button-green-s()}
    &--plain {@include button-plain()}

    &--link {
        @include button-text();
        background: none;
        border: 0;
        text-decoration: underline;
        transition: color .15s;
    }

    &[disabled],
    &.disabled,
    &:disabled {
        cursor: default;
        filter: saturate(.2)
        contrast(.5)
        brightness(1.5);

        * {pointer-events: none !important} /* stylelint-disable-line */
    }
}

[type='text'],
[type='email'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='url'],
textarea
{@include text-input-field()}

textarea {line-height: 1.47}

select {
    @include body-m();
    font-family: inherit;
    font-size: 1em;
    flex: 0 0 auto;
    width: 100%;
    padding: calc(.75em - 1px) .5em calc(.75em - 1px) 1em;
    border: 1px solid transparent;
    border-radius: var(--br-s);
    background-color: var(--c-white);
    color: inherit;
    cursor: pointer;
    transition: border .15s,
                box-shadow .15s;

    &:focus {
        box-shadow: var(--bs-realistic-faint);
        outline: none;
    }

    &[multiple] {
        overflow-y: auto;
        padding: .3rem;
        background-color: var(--c-background);

        option {
            padding: .4rem .7rem;
            margin-bottom: 1px;
            border-radius: var(--br-s);

            &:checked {
                background: var(--c-action) !important;
                color: var(--c-white);
            }
        }
    }
}

#root {
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
}

.bold {font-weight: bold}

[data-tooltip] {
    position: relative;

    // Fix tooltip z-index. Maybe rethink button filters?
    &:not([disabled]):hover {filter: none !important;} /* stylelint-disable-line */

    &:disabled::before {display: none;}

    &::before {
        @include body-xxs();
        content: attr(data-tooltip);
        opacity: 0;
        position: absolute;
        top: 95%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        min-width: 4.3em;
        padding: .5rem;
        box-shadow: var(--bs-realistic);
        border-radius: var(--br-s);
        background: var(--c-white);
        color: transparent;
        pointer-events: none;
        text-align: left;
        white-space: pre-wrap;
        transition: all .15s,
                    color .15s;
    }

    &:hover::before {
        top: 105%;
        opacity: 1;
        color: var(--c-black);
    }

    &.tooltip-right {
        &::before {
            top: 50%;
            right: auto;
            bottom: auto;
            left: 95%;
            transform: translateY(-50%);
        }

        &:hover::before {
            left: 105%;
            bottom: auto;
        }
    }

    &.tooltip-left {
        &::before {
            top: 50%;
            right: 95%;
            bottom: auto;
            left: auto;
            transform: translateY(-50%);
        }

        &:hover::before {
            right: 105%;
            bottom: auto;
        }
    }

    &.tooltip-top {
        &::before {
            top: auto;
            right: auto;
            bottom: 95%;
            left: 50%;
            transform: translateX(-50%);
        }

        &:hover::before {bottom: 105%}
    }
}

.description {@include body-l()}
