@import "_mixins.scss";
.CookieConsent {
    position: fixed;
    z-index: 7;
    inset: auto 0 0 0;
    display: flex;
    gap: 1rem 2rem;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
    padding: 1rem;
    box-shadow: var(--bs-focused);
    background: var(--c-background);

    button {padding: .5rem 1rem}
}
