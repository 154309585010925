@mixin h1() {
    word-break: break-word;
    font-family: var(--ff-title);
    font-size: clamp(2rem, 5vw, 2.5rem);
    font-weight: 700;
    line-height: 1.2;
    color: var(--c-title);
}
@mixin h2() {
    word-break: break-word;
    font-family: var(--ff-title);
    font-size: clamp(1.5rem, 5vw, 2rem);
    font-weight: 700;
    line-height: 1.1;
    color: var(--c-title);
}
@mixin h3() {
    word-break: break-word;
    font-family: var(--ff-title);
    font-size: clamp(1.3rem, 5vw, 1.5rem);
    font-weight: normal;
    line-height: 1.16667;
    color: var(--c-title);
}
@mixin h4($weight: 600) {
    word-break: break-word;
    font-family: var(--ff-title);
    font-size: clamp(1.1rem, 5vw, 1.25rem);
    font-weight: $weight;
    line-height: 1.2;
    color: var(--c-title);
}
@mixin h5() {
    word-break: break-word;
    font-family: var(--ff-title);
    font-size: clamp(.9rem, 5vw, 1rem);
    font-weight: 600;
    line-height: 1.5;
    color: var(--c-title);
}
@mixin body-l() {
    font-size: clamp(1.1rem, 5vw, 1.5rem);
    line-height: 1.5;
    font-weight: 500;
    color: var(--c-text);
}
@mixin body-m() {
    font-size: 1rem;
    line-height: 1.25;
    font-weight: normal;
}
@mixin body-s() {
    font-size: .875rem;
    line-height: 1.28571;
    font-weight: normal;
}
@mixin body-xxs() {
    font-size: .625rem;
    font-weight: normal;
    line-height: 1.6;
}
@mixin label-m() {
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.28571;
}
@mixin label-s() {
    font-size: .75rem;
    font-weight: 600;
    line-height: 1.5;
}
@mixin button-text() {
    color: var(--c-black);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.4;
}
@mixin button-primary() {
    @include button-text();
    display: block;
    background: #222222;
    color: var(--c-white);
    border: 1px solid var(--c-black-t1);
    text-align: center;
    user-select: none;
    padding: calc(.625em - 1px) 1em;
    min-width: max-content;
    width: 3rem;
    max-width: calc(100% - 3rem);
    border-radius: 8px;
}
@mixin button-secondary() {
    @include button-green-s();
    background: var(--c-secondary-action);
}
@mixin button-green() {
    @include button-text();
    font-size: 1.125rem;
    flex: 0 0 auto;
    display: inline-block;
    background: var(--c-action);
    color: var(--c-white);
    text-align: center;
    user-select: none;
    max-width: max-content;
    padding: .8em 1.5em;
    border-radius: 3em;

    &:not(
        [disabled],
        .disabled,
        :disabled
    ) {
        &:hover {
            background: var(--c-action-h);
            filter: brightness(.95);
        }

        &:active {
            background: var(--c-action-a);
            filter: brightness(.9);
        }
    }
}
@mixin button-green-s() {
    @include button-text();
    @include button-green();
    font-size: .875rem;
    padding: .7em 1.1em;
    color: var(--c-white);
}
@mixin button-plain() {
    padding: .5em 1em;
    margin-bottom: 1rem;

    &:hover {color: var(--c-gray)}
}
@mixin link() {
    color: var(--c-link);
    text-decoration: underline;
    transition: color .1s;

    &:hover {color: var(--c-link-h)}
}


// Other / Misc.
@mixin text-input-field() {
    @include body-m();
    appearance: none;
    font-family: inherit;
    line-height: initial; // Prevents cutoff text
    color: inherit;
    border-radius: var(--br-s);
    background-color: var(--c-background);
    width: 100%;
    border: 1px solid transparent;
    padding: calc(.75em - 1px) 1em;
    transition: border .2s,
                box-shadow .2s,
                filter .2s,
                background .2s;

    &:focus {
        box-shadow: var(--bs-realistic-faint);
        outline: 1px solid var(--c-black-t1);
        background: var(--c-white);
    }

    &:is(:disabled, :read-only):not(select) {filter: contrast(.3) brightness(1.3)}

    &::placeholder {opacity: .7}
}
@mixin visually-hidden() {
    position: absolute !important;
    z-index: -1 !important;
    opacity: .01 !important;
    clip-path: circle(.1px) !important;
    width: .1px !important;
    height: .1px !important;
    box-shadow: none !important;
    border: none !important;
}
@mixin plate {
    font-weight: bold;
    font-size: clamp(2rem, 2.5vw, 3rem);
    letter-spacing: -.042em;
    line-height: 1.2;
}
