@import "_mixins.scss";
.form-label {
    @include label-m();
    display: block;
    position: relative;
    max-width: 100%;
    margin-bottom: .75rem;
    text-align: left;

    .form-field {
        position: relative;
        z-index: 1;
        margin-top: .1rem;

        input {
            width: 25rem;
            max-width: 100%;
        }

        .invalid {border-color: var(--c-secondary-action)}

        textarea {
            position: relative;
            z-index: 1;
            margin: .5em 0;
            resize: none;
        }
    }

    .form-error {
        @include body-s();
        color: var(--c-secondary-action);
        position: relative;
        top: -2rem;
        opacity: 0;
        white-space: pre-wrap;
        transition: top .2s,
                    opacity .3s;

        &.has-error {
            top: .2em;
            opacity: 1;
        }
    }
}
