@import "_mixins.scss";
.top-notification {
    position: fixed;
    top: -4em;
    bottom: auto;
    display: flex;
    max-width: 100%;
    width: 100%;
    padding: .5em 3.5em .5em 1em;
    align-items: center;
    visibility: hidden;
    z-index: 16777271;
    opacity: 0;
    overflow: hidden;
    background: currentColor;
    transition: visibility .2s,
                opacity .2s,
                top .2s,
                background .2s;

    &.success {color: var(--c-action)}
    &.error {color: var(--c-secondary-action)}

    &.backendProblem {
        color: #fff;
        font-weight: 900;
        background: linear-gradient(45deg,
            #f00 0%,
            #ff0 20%,
            #0f0 40%,
            #0ff 60%,
            #00f 80%,
            #f00 100%
        ) 0 0 / 200% 200%;
        text-shadow: 1px 1px 1px #000;
        animation: backend-background 3.5s linear infinite;
        transition: transform .4s;

        &:hover {transform: rotate(90001deg) scale(1.5)}

        > svg {
            font-size: 2em;
            fill: #a52;
        }
    }

    &.half-width-left {
        width: 50%;
        left: 0;
    }

    &.no-navigation-bar {left: 0}

    &.half-width-right {
        width: 50%;
        left: 50%;
    }

    &.visible {
        visibility: visible;
        opacity: 1;
        top: 0;
    }

    .close-button {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
        display: flex;
        align-items: center;
        background: none;
        border: 0;
        padding: 0 1em;
        color: var(--c-white);
        transition: opacity .2s;

        &:hover {opacity: .7}
    }

    .icon-text-wrapper {
        flex: 1 1 auto;
        max-width: 100%;
        white-space: nowrap;
        text-align: center;

        > * {vertical-align: middle}

        > svg {
            border-radius: 50%;
            background: var(--c-white);
            color: currentColor;
        }
    }

    .notification-text {
        font-size: .9rem;
        font-weight: 500;
        white-space: normal;
        display: inline-block;
        max-width: calc(100% - 3em);
        margin-left: 1.5em;
        color: var(--c-white);
    }
}
