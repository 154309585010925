@import "_mixins.scss";
.login-modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 16777270;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    will-change: backdrop-filter;
    animation: inert-blur-behind .2s forwards;

    .login-modal {
        max-width: 98vw;
        padding: 3rem;
        margin: auto;
        border-radius: var(--br-l);
        box-shadow: var(--bs-realistic);
        background: var(--c-white);
        text-align: center;
        will-change: transform opacity;
        animation: slide-fade-in-top .2s forwards;
    }

    h3 {margin: 1rem auto 2rem}

    [type='submit'] {
        margin: 2rem auto;
        display: block;
    }
}
