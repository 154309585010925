@import "_mixins.scss";
.data-loading-wrapper {
    position: fixed;
    height: 100%;
    width: 100%;
    max-width: var(--max-view-width);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, .5);
    z-index: 1000;
    animation: fade-in 2s;
}
