@keyframes loading-dots {
    0%  {content: '   '}
    25% {content: '.  '}
    50% {content: '.. '}
    75% {content: '...'}
}

@keyframes loading-indicator-appear {
    100% {visibility: visible; opacity: 1}
}

@keyframes fade-in {
    0%   {opacity: 0}
    100% {opacity: 1}
}

@keyframes backend-background {
    100% {background-position: 200% 0}
}

@keyframes inert-blur {
    0%      {filter: blur(0px) saturate(1)  brightness(1)}
    100%    {filter: blur(4px) saturate(.7) brightness(.9)}
}

@keyframes inert-blur-behind {
    0%      {backdrop-filter: blur(0px) saturate(1)     brightness(1)}
    100%    {backdrop-filter: blur(4px) saturate(.7)    brightness(.9)}
}

@keyframes slide-fade-in-top {
    0%      {opacity: 0; transform: translateY(-.5rem)}
    100%    {opacity: 1; transform: translateY(0)}
}

@keyframes map-marker-bounce {
    0%      {transform: translate(-50%, -50%)               rotateZ(-45deg)}
    20%     {transform: translate(-50%, calc(-50% - .3rem)) rotateZ(-45deg)}
    40%     {transform: translate(-50%, -50%)               rotateZ(-45deg)}
    80%     {transform: translate(-50%, calc(-50% - .2rem)) rotateZ(-45deg)}
    100%    {transform: translate(-50%, -50%)               rotateZ(-45deg)}
}
